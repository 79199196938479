let percentage = "72%";
export default {
  en: {
    regulation: {
      dearClient: "Dear Client!",
      regulationText: `<strong>Benor Capital Ltd </strong> is authorised and regulated by the Financial Services Commissions (FSC) of Mauritius (Licence Number<strong> C118023678</strong>). Please note that this website is not directed at EU residents as it may contain information on services and products not subject to product intervention measures. Please visit the website of our EU regulated entity triomarkets.eu. By Staying on <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Terms and Conditions</a>) you confirm that this is a decision based on your sole and absolute discretion without any solicitation and/or recommendation from our side.`,
      goTo: "Go to",
      cookies: "This website uses cookies.",
      accept: "Accept",
      privacyPolicy: `Privacy Policy`,
    },
    register: {
      progress: {
        step1: {
          title: "Register",
          subTitle: "Create your account",
        },
        step2: {
          title: "Verify",
          subTitle: "Verify your identity",
        },
        step3: {
          title: "Trade",
          subTitle: "Fund and trade",
        },
      },
      steps: {
        step1: {
          title: "Account Registration",
          subTitle:
            "You are just a few simple steps away from placing your first trade",
          accountType: {
            individual: "Individual",
            company: "Company",
            fname: "Name",
            lname: "Last name",
            email: "Email",
            pass: "Password",
            rpass: "Repeat Password",
            country: "Country",
            phoneExt: "Phone Ext",
            phone: "Phone number",
            passwordHelp: `Password must be at least 8 characters long and contain at least one capital letter and number.
            The allowed special characters are:`,
          },
          validation: {
            fnameRequired: "Name is required",
            fnameLatin: "Name may only contain alphabetic characters",
            lnameRequired: "Last name is required",
            lnameLatin: "Name may only contain alphabetic characters",
            companyLatin: "Company may only contain alphabetic characters",
            emailRequired: "Email is required",
            emailValid: "Email must be valid email",
            countryRequired: "Country is required",
            phoneExtRequired: "Phone ext is required",
            phoneRequired: "Phone is required",
            companyRequired: "Company is required",
            passwordRequired: "Password is required",
            passwordLength: "Password must be minimum 8 characters",
            passwordChar: "Password does not meet the requirements.",
            passwordNotMatch: "Passwords do not match",
            eulaRequired: "Must Accept Terms and Conditions",
          },
          eula: ({
            named,
          }) => `I hereby acknowledge and agree that the Company will keep and process my Personal Data for regulatory purposes in accordance with the Privacy Policy. 
                 I have read, understood and accepted the <a class="text-purple" target="_blank" href="${named(
                   "clientAgreementUrl"
                 )}">Client Agreement</a>,
                 <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >Privacy Policy</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("riskDisclosureUrl")}"
          >Risk Disclosure Policy</a>,`,
          registerBtn: "SIGN UP",
          alreadyRegistered: "Already registered? Then ",
          loginBtn: "Login",
        },
        step2: {
          title: "Email Verification",
          subTitle:
            "You are just 2 simple steps away from placing your first trade",
          oneMoreStep: ({ named }) => `Just one more step ${named("name")}!`,
          verifyEmail: "You need to verify your email address",
          startAgain: "Start again!",
          receiveEmail: ({ named }) =>
            `You will receive a verification email on <strong>${named(
              "email"
            )}</strong> containing a validation link in a few minutes.`,
          notReceiveEmail:
            "If you do not receive this email, please check your spam folder and make sure that inbox is active and not full.",
          resendEmail: "If you continue to experience problems, please try",
          resendEmailLink: "resending the email verification",
          emailResendAgain: ({ named }) =>
            `<p>Your verification email was re-sent to <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            You can try resending again in ${named("timer")} ${named("seconds")}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `OOPS! Looks like you haven't confirmed your email address. Just in case we've dispatched a new verification email to <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `or contact ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } for help`,
          trioMarketsTeam: "TrioMarkets&trade; Team",
          pleaseWait: ({ named }) =>
            `Please wait ${named("timer")} ${named("seconds")}`,
          seconds: "seconds",
          continuebtn: "CONTINUE",
        },
        step3: {
          success: "Congratulations",
          activatedAccount:
            "Your account is now active! You can login and start trading!",
          verifiedAccount: "Your new account has been successfully verified!",
          loginAccount: "You may now login to your new account!",
          loginBtn: "Go to Login",
        },
      },
    },
    login: {
      email: "Your email",
      password: "Your secure password",
      loginBtn: "Login",
      noAccountLink: "Don't have an account yet?",
      clientPortal: "Sign in to TrioMarkets™ Client Portal",
      signUpHere: "Sign up here",
    },
    forgotPassword: {
      title: "Forgot your password?",
      password: "Enter new password",
      repeatPassword: "Retype new password",
      resetPasswordBtn: "Reset password",
      backToLoginBtn: "Back to login",
      accountNotFound: "Account not found",
      emailSent: "Password reset instructions sent successfully",
      changePasswordBtn: "Change Password",
      resetPasswordSuccess: "Your password has been changed!",
      resetPasswordLinkExpired: "Your link has expired!",
    },
    disclaimer: ({ named }) =>
      `Invest Responsibly: CFDs are complex instruments and come with a high risk of losing money rapidly due to leverage. 
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage} of retail investor accounts lose money when trading CFDs with this provider.</b>`
    }  You should consider whether you understand how CFDs work and whether you can afford to take the high risk of losing your money.`,
  },
  fr: {
    regulation: {
      dearClient: "Cher Clients!",
      regulationText: `<strong>Benor Capital Ltd</strong> est autorisée et réglementée par la Financial Services Commissions (FSC) de Maurice (Numéro de Licence <strong>C118023678</strong>). Veuillez noter que ce site Web ne s'adresse pas aux résidents de l'UE car il peut contenir des informations sur des services et des produits non soumis aux mesures d'intervention sur les produits. Veuillez visiter le site Web de notre entité réglementée par l'UE triomarkets.eu. En poursuivant, vous acceptez d'ouvrir un compte chez <strong>Benor Capital Ltd</strong> (<a class="text-purple" target="_blank" href="https://www.triomarkets.com/about-us/legal-documents/">Termes et Conditions</a>) et vous confirmez qu'il s'agit d'une décision basée sur votre seul et absolu jugement sans aucune sollicitation et/ou recommandation de notre part.`,
      goTo: `Aller sur`,
      accept: "Acceptez",
      privacyPolicy: `Politique de Confidentialité`,
    },
    register: {
      progress: {
        step1: {
          title: "S'inscrire",
          subTitle: "Créer votre compte",
        },
        step2: {
          title: "Vérifier",
          subTitle: "Vérifiez votre identité",
        },
        step3: {
          title: "Trader",
          subTitle: "Créditer votre compte et trader",
        },
      },
      steps: {
        step1: {
          title: "Enregistrement du compte",
          subTitle:
            "Vous n'êtes qu'à quelques pas de votre première transaction",
          accountType: {
            individual: "Particulier",
            company: "Entreprise",
            fname: "Prénom",
            lname: "Nom",
            email: "Email",
            pass: "Mot de passe",
            rpass: "Confirmer mot de passe",
            country: "Pays",
            phoneExt: "Phone Ext",
            phone: "Numéro de téléphone",
            passwordHelp: `Le mot de passe doit comporter au moins 8 caractères et contenir au moins une lettre majuscule et un chiffre. Les caractères spéciaux autorisés sont:`,
          },
          validation: {
            fnameRequired: "Prénom obligatoire",
            fnameLatin:
              "Le nom ne peut contenir que des caractères alphabétiques",
            lnameRequired: "Nom obligatoire",
            lnameLatin:
              "Le nom de famille ne peut contenir que des caractères alphabétiques",
            companyLatin:
              "La société ne peut contenir que des caractères alphabétiques",
            emailRequired: "Email obligatoire",
            emailValid: "L'e-mail doit être un e-mail valide",
            countryRequired: "Pays obbligatoria",
            phoneExtRequired: "Phone ext is required",
            phoneRequired: "Numéro de téléphone obligatoire",
            companyRequired: "Entreprise bligatoire",
            passwordRequired: "Mot de passe bligatoire",
            passwordLength:
              "Le mot de passe doit comporter au moins 8 caractères",
            passwordChar: "Le mot de passe ne répond pas aux exigences.",
            passwordNotMatch: "Les mots de passe ne correspondent pas",
            eulaRequired: "Doit accepter les termes et conditions",
          },
          eula: ({
            named,
          }) => `Je reconnais et accepte par la présente que la société conserve et traite mes données personnelles à des fins réglementaires conformément à la politique de confidentialité.
          J'ai lu, compris et accepté la
          <a class="text-purple"
            target="_blank"
            href="${named("clientAgreementUrl")}"
          >le contrat client de TrioMarkets</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >la Politique de confidentialité</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("riskDisclosureUrl")}"
          >Politique de divulgation des risques</a>,`,
          registerBtn: "S'INSCRIRE MAINTENANT",
          alreadyRegistered: "Déjà inscrit? Alors ",
          loginBtn: "Connectez-vous",
        },
        step2: {
          title: "Validation de votre e-mail",
          subTitle: "Vous n'êtes qu'à deux pas de votre première transaction",
          oneMoreStep: ({ named }) => `Encore une étape ${named("name")}!`,
          verifyEmail: "Vous devez valider votre adresse e-mail",
          startAgain: "Recommencez!",
          receiveEmail: ({ named }) =>
            `Vous recevrez dans un instant un e-mail contenant un lien de validation sur <strong>${named(
              "email"
            )}</strong> containing a validation link in a few minutes.`,
          notReceiveEmail:
            "Si vous ne recevez pas cet e-mail, veuillez vérifier votre dossier des courriers indésirables et assurez-vous que la boîte de réception est active et non pleine.",
          resendEmail: "Si vous continuez à rencontrer des problèmes, veuillez",
          resendEmailLink: "essayer de renvoyer l'e-mail de validation",
          emailResendAgain: ({ named }) =>
            `<p>Votre e-mail de vérification a été renvoyé à <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            Vous pouvez réessayer de l'envoyer dans ${named("timer")} ${named(
              "seconds"
            )}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `OOPS! Il semblerait que vous n'avez pas encore confirmé votre adresse e-mail <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `ou contactez ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            }pour lui`,
          trioMarketsTeam: "L'Equipe TrioMarkets&trade;",
          pleaseWait: ({ named }) =>
            `Veuillez patienter ${named("timer")} ${named("seconds")}`,
          seconds: "secondes",
          continuebtn: "CONTINUEZ",
        },
        step3: {
          success: "Félicitations",
          activatedAccount:
            "Votre compte est à présent actif ! Vous pouvez vous connecter et commencer à trader!",
          verifiedAccount: "Votre nouveau compte a été validé avec succès!",
          loginAccount: "Vous pouvez à présent vous connecter à votre compte!",
          loginBtn: "SE CONNECTER",
        },
      },
    },
    login: {
      email: "Votre e-mail",
      password: "Votre mot de passe sécurisé",
      loginBtn: "Connectez-vous",
      noAccountLink: "Vous n'avez pas encore de compte?",
      clientPortal: "Connectés vous sur votre espace client TrioMarkets™",
      signUpHere: "Enregistrer vous ici",
    },
    forgotPassword: {
      title: "Mot de passe oublié?",
      password: "Entrez un nouveau mot de passe",
      repeatPassword: "Re-taper le nouveau mot de passe",
      resetPasswordBtn: "Réinitialiser le mot de passe",
      backToLoginBtn: "Retour connexion",
      accountNotFound: "Compte non trouvé",
      emailSent: "Le courriel a été envoyé avec succès",
      changePasswordBtn: "Changer le mot de passe",
      resetPasswordSuccess: "Votre mot de passe a été changé!",
      resetPasswordLinkExpired: "Votre lien a expiré!",
    },
    disclaimer: ({ named }) =>
      `Investir de manière responsable : les CFD sont des instruments complexes comportant un risque élevé de perte d'argent rapide en raison de l'effet de levier. 
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage} des investisseurs particuliers perdent de l'argent lorsqu'ils négocient les CFD avec ce fournisseur.</b>`
    } Assurez-vous que vous comprenez le fonctionnement des CFD et que vous pouvez vous permettre de prendre le risque de perdre votre argent.`,
  },
  it: {
    regulation: {
      dearClient: "Caro Cliente!",
      regulationText: `<strong>Benor Capital Ltd</strong> è autorizzata e regolamentata dalla Financial Services Commissions (FSC) di Mauritius (numero di licenza <strong>C118023678</strong>).Si prega di notare che questo sito non è rivolto ai residenti nell'UE in quanto può contenere informazioni su servizi e prodotti non soggetti a misure di intervento sui prodotti. Si prega di visitare il sito web della nostra entità regolamentata UE triomarkets.eu. Procedendo, accetti di aprire un conto con <strong>Benor Capital Ltd</strong> (<a href="https://www.triomarkets.com/about-us/legal-documents/">Termini e condizioni</a>) e confermi che questa è una decisione basata sulla tua sola e assoluta discrezione senza alcuna sollecitazione e/o raccomandazione da parte nostra.`,
      goTo: `Vai nel`,
      accept: "Accetta",
      privacyPolicy: `Politica sulla Riservatezza`,
    },
    register: {
      progress: {
        step1: {
          title: "Iscriviti",
          subTitle: "Crea il tuo nuovo conto",
        },
        step2: {
          title: "Verifica",
          subTitle: "Verificare la propria identità",
        },
        step3: {
          title: "Trada",
          subTitle: "Deposita e trada",
        },
      },
      steps: {
        step1: {
          title: "Registrazione Conto",
          subTitle: "Sei solo a pochi semplici click dal tuo primo trade",
          accountType: {
            individual: "Individuo",
            company: "Società",
            fname: "Nome",
            lname: "Cognome",
            email: "Email",
            pass: "Password",
            rpass: "Ripeti la Password",
            country: "Paese",
            phoneExt: "Phone Ext",
            phone: "Telefono",
            passwordHelp: `La password deve essere lunga almeno 8 caratteri e contenere almeno una lettera maiuscola e un numero. I caratteri speciali consentiti sono:`,
          },
          validation: {
            fnameRequired: "Nome obbligatorio",
            fnameLatin: "Il nome può contenere solo caratteri alfabetici",
            lnameRequired: "Cognome obbligatorio",
            lnameLatin: "Il cognome può contenere solo caratteri alfabetici",
            companyLatin: "L'azienda può contenere solo caratteri alfabetici",
            emailRequired: "Email obbligatoria",
            emailValid: "L'e-mail deve essere un'e-mail valida",
            countryRequired: "Paese obbligatoria",
            phoneExtRequired: "Phone ext is required",
            phoneRequired: "Telefono obbligatorio",
            companyRequired: "Società obbligatorio",
            passwordRequired: "Password obbligatorio",
            passwordLength: "La password deve essere di almeno 8 caratteri",
            passwordChar: "La password non soddisfa i requisiti.",
            passwordNotMatch: "Le password non corrispondano",
            eulaRequired: "Deve accettare Termini e condizioni",
          },
          eula: ({
            named,
          }) => `Con la presente riconosco e accetto che la Società conservi ed elabori i miei Dati Personali a fini normativi in conformità con l'Informativa sulla Privacy. Ho letto, compreso e accettato la
          <a class="text-purple
            target="_blank"
            href="${named("clientAgreementUrl")}"
          >il Contratto con il cliente</a>,
          <a class="text-purple
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >la Politica sulla privacy</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("riskDisclosureUrl")}"
          >Politica di divulgazione dei rischi</a>,`,
          registerBtn: "ISCRIVITI ADESSO",
          alreadyRegistered: "Sei già registrato? ",
          loginBtn: "Accedi",
        },
        step2: {
          title: "Verifica Email",
          subTitle: "Sei solo a 2 semplici click dal tuo primo trade",
          oneMoreStep: ({ named }) =>
            `Ancora solamente un click ${named("name")}!`,
          verifyEmail: "Devi verificare la tua email",
          startAgain: "Ricomincia!",
          receiveEmail: ({ named }) =>
            `Riceverai una email di verifica a <strong>${named(
              "email"
            )}</strong> contenente un link di convalida in pochi minuti.`,
          notReceiveEmail:
            "Se non hai ricevuto questa email, per cortesia controlla la cartella spam e accertati che la casella di posta elettronica sia attiva e non sia piena.",
          resendEmail: "Se continui ad avere problemi, per cortesia",
          resendEmailLink: "prova a rifare la Verificare dell'email",
          emailResendAgain: ({ named }) =>
            `<p>La tua email di verifica è stata reinviata a <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            Puoi provare a inviare di nuovo tra ${named("timer")} ${named(
              "seconds"
            )}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `OOPS! Sembra che non hai confermato il tuo indirizzo email. Solo nel caso in cui abbiamo inviato una nuova email di verifica a <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `o contattare ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } per assistenza`,
          trioMarketsTeam: "TrioMarkets&trade; Team",
          pleaseWait: ({ named }) =>
            `Per cortesia attendi ${named("timer")} ${named("seconds")}`,
          seconds: "secondi",
          continuebtn: "CONTINUA",
        },
        step3: {
          success: "Congratulazioni",
          activatedAccount:
            "Ora il tuo conto è attivo! Accedi e inizia a fare trading!",
          verifiedAccount:
            "Il tuo nuovo account è stato verificato con successo!",
          loginAccount: "Ora puoi accedere al tuo nuovo conto!",
          loginBtn: "VAI AL LOGIN",
        },
      },
    },
    login: {
      email: "Tua mail",
      password: "La tua password di sicurezza",
      loginBtn: "Accedi",
      noAccountLink: "Non hai ancora il conto?",
      clientPortal: "Accedi al Portale Cliente di TrioMarkets™",
      signUpHere: "Iscriviti qui",
    },
    forgotPassword: {
      title: "Hai dimenticato la password?",
      password: "Inserire una nuova password",
      repeatPassword: "Ridigitare la nuova password",
      resetPasswordBtn: "Resetta la password",
      backToLoginBtn: "Torna al login",
      accountNotFound: "Account non trovato",
      emailSent:
        "Istruzioni per la reimpostazione della password inviate correttamente",
      changePasswordBtn: "Cambia la password",
      resetPasswordSuccess: "La tua password è stata modificata!",
      resetPasswordLinkExpired: "Il tuo link è scaduto!",
    },
    disclaimer: ({ named }) =>
      `Investi con responsabilità: I CFD sono strumenti complessi e comportano un elevato rischio di perdita del denaro in tempi brevi a causa della leva. 
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage} dei conti degli investitori al dettaglio perdono denaro quando fanno trading CFD con questo fornitore.</b>`
    } Dovresti considerare se comprendi come funzionano i CFD e se puoi permetterti di correre il rischio elevato di perdere i tuoi soldi.`,
  },
  de: {
    regulation: {
      dearClient: "Lieber Kunde!",
      regulationText: `<strong>Benor Capital Ltd</strong> ist autorisiert und wird durch die Financial Services Commissions (FSC) von Mauritius (Lizenznummer <strong>C118023678</strong>) reguliert. Bitte beachten Sie, dass diese Website sich nicht an in der EU ansässige Personen richtet, da sie Informationen über Dienstleistungen und Produkte enthalten kann, die nicht den Produktinterventionsmaßnahmen unterliegen. Bitte besuchen Sie die Website unseres EU-regulierten Unternehmens triomarkets.eu. Indem Sie fortfahren, erklären Sie sich damit einverstanden, ein Konto bei <strong>Benor Capital Ltd</strong>. zu eröffnen (<a class="text-purple" traget="_blank" href="https://www.triomarkets.com/about-us/legal-documents/">Allgemeine Geschäftsbedingungen</a>) und bestätigen, dass dies eine Entscheidung ist, die auf Ihrem alleinigen und absoluten Ermessen basiert, ohne jegliche Aufforderung und/oder Empfehlung von unserer Seite.`,
      goTo: "Gehen Sie zu",
      accept: "Akzeptieren",
      privacyPolicy: `Datenschutzrichtlinien`,
    },
    register: {
      progress: {
        step1: {
          title: "Registrieren",
          subTitle: "Konto eröffnen",
        },
        step2: {
          title: "Verifizieren",
          subTitle: "Überprüfen Sie Ihre Identität",
        },
        step3: {
          title: "Traden",
          subTitle: "Einzahlen und traden",
        },
      },
      steps: {
        step1: {
          title: "Kontoregistrierung",
          subTitle:
            "Sie sind nur ein paar einfache Schritte von Ihrem ersten Trade entfernt",
          accountType: {
            individual: "Individuelles",
            company: "Firma",
            fname: "Name",
            lname: "Nachname",
            email: "E-Mail",
            pass: "Passwort",
            rpass: "Passwort wiederholen",
            country: "Land",
            phoneExt: "Durchwahl",
            phone: "Telefonnummer",
            passwordHelp: `Das Passwort muss mindestens 8 Zeichen lang sein und mindestens einen Großbuchstaben und eine Zahl enthalten. Die erlaubten Sonderzeichen sind:`,
          },
          validation: {
            fnameRequired: "Name ist erforderlich",
            fnameLatin: "Der Name darf nur Buchstaben enthalten",
            lnameRequired: "Nachname ist erforderlich",
            lnameLatin: "Der Nachname darf nur Buchstaben enthalten",
            companyLatin: "Unternehmen darf nur Buchstaben enthalten",
            emailRequired: "E-Mail ist erforderlich",
            emailValid: "E-Mail muss eine gültige E-Mail sein",
            countryRequired: "Land ist erforderlich",
            phoneExtRequired: "Durchwahl ist erforderlich",
            phoneRequired: "Telefonnummer ist erforderlich",
            companyRequired: "Firma ist erforderlich",
            passwordRequired: "Passwort ist erforderlich",
            passwordLength: "Das Passwort muss mindestens 8 Zeichen lang sein",
            passwordChar: "Das Passwort entspricht nicht den Anforderungen.",
            passwordNotMatch: "Passwörter stimmen nicht überein",
            eulaRequired: "Allgemeine Geschäftsbedingungen akzeptieren",
          },
          eula: ({
            named,
          }) => `Ich erkenne hiermit an und stimme zu, dass das Unternehmen meine persönlichen Daten für regulatorische Zwecke gemäß der Datenschutzrichtlinie aufbewahrt und verarbeitet. Ich habe gelesen, verstanden und akzeptiere die
          <a class="text-purple"
            target="_blank"
            href="${named("clientAgreementUrl")}"
          >die TrioMarkets-Kundenvereinbarung</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >die Datenschutzbestimmungen gelesen</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("riskDisclosureUrl")}"
          >Risiko-Offenlegungspolitik</a>,`,
          registerBtn: "JETZT REGISTRIEREN",
          alreadyRegistered: "Bereits registriert? Dann bitte ",
          loginBtn: "einloggen",
        },
        step2: {
          title: "E-Mail Registrierung",
          subTitle:
            "Sie sind nur ein paar einfache Schritte von Ihrem ersten Trade entfernt",
          oneMoreStep: ({ named }) => `Nur noch ein Schritt ${named("name")}!`,
          verifyEmail: "Sie müssen Ihre E-Mail-Adresse verifizieren",
          startAgain: "Neu anfangen!",
          receiveEmail: ({ named }) =>
            `Sie erhalten eine Bestätigungs-E-Mail an <strong>${named(
              "email"
            )}</strong> Sie erhalten einen Validierungs-link in wenigen Minuten.`,
          notReceiveEmail:
            "Wenn Sie diese E-Mail nicht erhalten, überprüfen Sie bitte Ihren Spam-Ordner und stellen Sie sicher, dass der Posteingang aktiv und nicht voll ist.",
          resendEmail: "Wenn Sie weiterhin Probleme haben, versuchen Sie bitte",
          resendEmailLink: "die E-Mail-Bestätigung erneut senden",
          emailResendAgain: ({ named }) =>
            `<p>Ihre Bestätigungs-E-Mail wurde erneut gesendet an <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            Sie können in  ${named("timer")} ${named("seconds")} erneut senden 
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `HOPPLA! Sieht so aus, als hätten Sie Ihre E-Mail-Adresse nicht bestätigt. <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `oder wenden Sie sich an ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } um Hilfe zu erhalten`,
          trioMarketsTeam: "TrioMarkets&trade;-Team",
          pleaseWait: ({ named }) =>
            `Bitte warten ${named("timer")} ${named("seconds")}`,
          seconds: "SEKUNDEN",
          continuebtn: "WEITER",
        },
        step3: {
          success: "Herzlichen Glückwunsch",
          activatedAccount:
            "Ihr Konto ist jetzt aktiv! Sie können sich anmelden und mit dem Handel beginnen!",
          verifiedAccount: "Ihr neues Konto wurde erfolgreich verifiziert!",
          loginAccount: "Sie können sich jetzt in Ihrem neuen Konto anmelden!",
          loginBtn: "GEHE ZU LOGIN",
        },
      },
    },
    login: {
      email: "Ihre E-Mail",
      password: "Ihr Sicherheitspasswort",
      loginBtn: "einloggen",
      noAccountLink: "Sie haben noch keinen Account?",
      clientPortal: "Anmeldung zum TrioMarkets™ Kundenportal",
      signUpHere: "Hier anmelden",
    },
    forgotPassword: {
      title: "Passwort vergessen?",
      password: "Neues Passwort eingeben",
      repeatPassword: "Geben Sie das neue Passwort erneut ein",
      resetPasswordBtn: "Passwort zurücksetzen",
      backToLoginBtn: "Zurück zur Anmeldung",
      accountNotFound: "Konto nicht gefunden",
      emailSent:
        "Anweisungen zum Zurücksetzen des Passwortes wurden erfolgreich gesendet",
      changePasswordBtn: "Passwort ändern",
      resetPasswordSuccess: "Ihr Passwort wurde geändert!",
      resetPasswordLinkExpired: "Ihr Link ist abgelaufen!",
    },
    disclaimer: ({ named }) =>
      `Verantwortungsbewusst investieren: CFDs sind komplexe Instrumente und bergen ein hohes Risiko, aufgrund von Hebeleffekten schnell Geld zu verlieren. 
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage} der Privatanlegerkonten verlieren Geld, wenn sie mit diesem Anbieter CFDs handeln.</b>`
    } Sie sollten überlegen, ob Sie verstehen, wie CFDs funktionieren, und ob Sie es sich leisten können, das hohe Risiko einzugehen, Ihr Geld zu verlieren.`,
  },
  es: {
    regulation: {
      dearClient: "Estimado Cliente!",
      regulationText: `<strong>Benor Capital Ltd </strong> está autorizada y regulada por las Comisiones de Servicios Financieros (FSC) de Mauricio (Número de licencia<strong> C118023678</strong>). Por favor tenga en cuenta que este sitio web no está dirigido a residentes de la UE ya que puede contener información sobre servicios y productos no sujetos a la intervención del producto medidas. Visite el sitio web de nuestra UE entidad regulada triomarkets.eu. Al proceder, usted acepta abrir una cuenta con <strong>Benor Capital Ltd </strong> (<a class="text-purple" target="_blank" href="https://www.triomarkets.com/about-us/legal-documents/">Términos y Condiciones</a>) y usted confirma que esta es una decisión basada en su única y absoluta discreción sin ninguna solicitud y/o recomendación de nuestra parte.`,
      goTo: `Ir`,
      accept: "Aceptar",
      privacyPolicy: `Política de Privacidad`,
    },
    register: {
      progress: {
        step1: {
          title: "Registrarse",
          subTitle: "Crea la tu cuenta",
        },
        step2: {
          title: "Verificar",
          subTitle: "Verifique su identidad",
        },
        step3: {
          title: "Trade",
          subTitle: "Depósita y Trade Ahora",
        },
      },
      steps: {
        step1: {
          title: "Registro de Cuenta",
          subTitle:
            "Está a solo unos simples pasos de realizar su primera operación",
          accountType: {
            individual: "Individual",
            company: "Empresa",
            fname: "Nombre",
            lname: "Apellido",
            email: "Correo electrónico",
            pass: "Contraseña",
            rpass: "Repite la Contraseña",
            country: "País",
            phoneExt: "Phone Ext",
            phone: "Número de teléfono",
            passwordHelp: `La contraseña debe tener al menos 8 caracteres y contener como mínimo una letra mayúscula y un número. Los caracteres especiales permitidos son:`,
          },
          validation: {
            fnameRequired: "Se requiere el nombre",
            fnameLatin: "El nombre solo puede contener caracteres alfabéticos",
            lnameRequired: "Se requiere apellido",
            lnameLatin:
              "El apellido solo puede contener caracteres alfabéticos",
            companyLatin:
              "La empresa solo puede contener caracteres alfabéticos",
            emailRequired: "Correo electronico es requerido",
            emailValid:
              "El correo electrónico debe ser un correo electrónico válido",
            countryRequired: "El país es obligatorio",
            phoneExtRequired: "Phone ext is required",
            phoneRequired: "Se requiere teléfono",
            companyRequired: "Se requiere empresa",
            passwordRequired: "Se requiere contraseña",
            passwordLength: "La contraseña debe tener al menos 8 caracteres",
            passwordChar: "La contraseña no cumple los requisitos.",
            passwordNotMatch: "Las contraseñas no coinciden",
            eulaRequired: "Debe aceptar los términos y condiciones",
          },
          eula: ({
            named,
          }) => `Por la presente reconozco y acepto que la Empresa conservará y tratará mis Datos Personales con fines reglamentarios de conformidad con la Política de Privacidad. He leído, comprendo y acepto la
          <a class="text-purple"
            target="_blank"
            href="${named("clientAgreementUrl")}"
          >Acuerdo de cliente</a>de TrioMarkets™,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >Política de Privacidad</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("riskDisclosureUrl")}"
          >Política de Divulgación de Riesgos</a>,`,
          registerBtn: "REGISTRARSE",
          alreadyRegistered: "Ya registrado? Luego ",
          loginBtn: "inicie sesión",
        },
        step2: {
          title: "Verificacion de Email",
          subTitle:
            "Estás a solo 2 simples pasos de colocar tu primera operación",
          oneMoreStep: ({ named }) => `Sólo un paso más ${named("name")}!`,
          verifyEmail: "Necesitas verificar tu dirección de correo electrónico",
          startAgain: "Empezar de nuevo!",
          receiveEmail: ({ named }) =>
            `Recibirás un correo electrónico de verificación el <strong>${named(
              "email"
            )}</strong>que contiene un enlace de validación en unos minutos.`,
          notReceiveEmail:
            "Si no recibe este correo electrónico, por favor verifique su carpeta de correo no deseado y asegúrese de que la bandeja de entrada esté activa y no lleno.",
          resendEmail: "Si continúa experimentando problemas, intente reenviar",
          resendEmailLink: "la verificación de correo electrónico",
          emailResendAgain: ({ named }) =>
            `<p>Su correo electrónico de verificación fue reenviado a <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            Puede intentar reenviar de nuevo en ${named("timer")} ${named(
              "seconds"
            )}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `OOPS! Parece que no ha confirmado su dirección de correo electrónico. <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `o póngase en contacto con ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } para obtener ayuda`,
          trioMarketsTeam: "TrioMarkets&trade; Team",
          pleaseWait: ({ named }) =>
            `Por favor espere ${named("timer")} ${named("seconds")}`,
          seconds: "segundos",
          continuebtn: "SEGUIR",
        },
        step3: {
          success: "Muy bien",
          activatedAccount:
            "Su cuenta ahora está activa! Puede iniciar sesión y comienzar el trading!",
          verifiedAccount: "Tu nueva cuenta ha sido verificada con éxito!",
          loginAccount: "Puede iniciar ahora la sesión en su nueva cuenta!",
          loginBtn: "IR A INICIAR SESIÓN",
        },
      },
    },
    login: {
      email: "Tu correo electrónico",
      password: "Tu contraseña segura",
      loginBtn: "IR A INICIAR SESIÓN",
      noAccountLink: "Aún no tienes una cuenta?",
      clientPortal: "Inicie sesión en el portal de clientes de TrioMarkets™",
      signUpHere: "Inscríbase aquí",
    },
    forgotPassword: {
      title: "Olvidaste tu contraseña?",
      password: "Ingrese nueva clave",
      repeatPassword: "Reescriba nueva contraseña",
      resetPasswordBtn: "Restablecer la contraseña",
      backToLoginBtn: "Volver atrás para iniciar sesión",
      accountNotFound: "Cuenta no encontrada",
      emailSent: "Instrucciones para restablecer contraseña enviada con éxito",
      changePasswordBtn: "Cambia la contraseña",
      resetPasswordSuccess: "Tu contraseña ha sido cambiada!",
      resetPasswordLinkExpired: "Tu enlace ha caducado!",
    },
    disclaimer: ({ named }) =>
      `Invierta responsablemente: los CFD son instrumentos complejos y vienen con un alto riesgo de perder dinero rápidamente debido al apalancamiento.
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage} de las cuentas de inversores minoristas perder dinero al operar con CFD con este proveedor.</b>`
    } Debe considerar si entiende cómo Los CFD funcionan y si puede permitirse el lujo de tomar el alto riesgo de perder su dinero.`,
  },
  cn: {
    regulation: {
      dearClient: "尊敬的客户！",
      regulationText: `<strong>Benor Capital Ltd </strong> 由毛里求斯金融服务委员会  (FSC) 授权和监管 (许可证号<strong> C118023678</strong>)。请注意，本网站不针对欧盟居民，因为它可能包含不受产品干预措施约束的服务和产品的信息。 请访问我们受欧盟监管的实体 triomarkets.eu 的网站。通过继续操作，您同意在  <strong>Benor Capital Ltd </strong> 开设账户 (<a class="text-purple" target="_blank" href="https://www.triomarkets.com/about-us/legal-documents/">条款和条件</a>)，并且您确认这是基于您的唯一和绝对酌情决定权的决定，没有我们方面的任何请求和/或建议。`,
      goTo: "去",
      accept: "接受",
      privacyPolicy: `隐私政策`,
    },
    register: {
      progress: {
        step1: {
          title: "登记",
          subTitle: "创建您的帐户",
        },
        step2: {
          title: "验证",
          subTitle: "验证您的电子邮件",
        },
        step3: {
          title: "交易",
          subTitle: "资金和交易",
        },
      },
      steps: {
        step1: {
          title: "账户注册",
          subTitle: "您只需要几个步骤即可开始您的第一笔交易",
          accountType: {
            individual: "个人",
            company: "公司",
            fname: "名氏",
            lname: "姓氏",
            email: "电子邮件",
            pass: "密码",
            rpass: "重复输入密码",
            country: "国家",
            phoneExt: "Phone Ext",
            phone: "电话号码",
            passwordHelp: `密码长度至少为 8 个字符，至少包含一个大写字母和一个数字。允许使用的特殊字符有`,
          },
          validation: {
            fnameRequired: "名氏为必填项",
            fnameLatin: "名称只能包含字母字符",
            lnameRequired: "姓氏为必填项",
            lnameLatin: "姓氏只能包含字母字符",
            companyLatin: "公司只能包含字母字符",
            emailRequired: "电子邮件是必需填项",
            emailValid: "电子邮件必须是有效的电子邮件",
            countryRequired: "地区为必填项",
            phoneExtRequired: "Phone ext is required",
            phoneRequired: "电话号码为必填项",
            companyRequired: "公司为必填项",
            passwordRequired: "密码是必填项",
            passwordLength: "密码长度至少为 8 个字符",
            passwordChar: "密码不符合要求。",
            passwordNotMatch: "密码不匹配",
            eulaRequired: "必须接受条款和条件",
          },
          eula: ({
            named,
          }) => `我在此承认并同意，公司将根据 GDPR* 出于监管目的保留和处理我的个人数据。我已阅读、理解并接受 TrioMarkets&trade
          <a class="text-purple"
            target="_blank"
            href="${named("clientAgreementUrl")}"
          >客户协议</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >隐私政策</a>`,
          registerBtn: "注册",
          alreadyRegistered: "已经注册？ 然后 ",
          loginBtn: "登录",
        },
        step2: {
          title: "电子邮件验证",
          subTitle: "您还需要 2 个简单的步骤才能开始您的第一笔交易",
          oneMoreStep: ({ named }) => `只差一个步骤 ${named("name")}!`,
          verifyEmail: "您需要验证您的电子邮件地址",
          startAgain: "重新开始",
          receiveEmail: ({ named }) =>
            `您将在几分钟内收到 <strong>${named(
              "email"
            )}</strong> 封包含验证链接的验证电子邮件。`,
          notReceiveEmail:
            "如果您没有收到这封电子邮件，请检查您的垃圾邮件文件夹并确保收件箱处于活动状态且未满。",
          resendEmail: "如果您仍然遇到问题，请尝试",
          resendEmailLink: "重新发送电子邮件验证",
          emailResendAgain: ({ named }) =>
            `<p>您的验证电子邮件已重新发送至 <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            您可以在 ${named("timer")} ${named("seconds")}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `哎呀！看来您还没有确认您的电子邮件地址 <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `或联系 ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } 寻求帮助`,
          trioMarketsTeam: "TrioMarkets&trade; 团队",
          pleaseWait: ({ named }) =>
            `请稍等 ${named("timer")} ${named("seconds")}`,
          seconds: "秒",
          continuebtn: "继续",
        },
        step3: {
          success: "祝贺您",
          activatedAccount: "您的账户现已激活！您可以登录并开始交易！",
          verifiedAccount: "您的新帐户已成功验证!",
          loginAccount: "您现在可以登录到您的新帐户!",
          loginBtn: "登录",
        },
      },
    },
    login: {
      email: "你的邮件",
      password: "您的安全密码",
      loginBtn: "登录",
      noAccountLink: "还没有账号?",
      clientPortal: "登录到TrioMarkets™客户门户网站",
      signUpHere: "在此注册",
    },
    forgotPassword: {
      title: "忘记您的密码了吗?",
      password: "输入新密码",
      repeatPassword: "重新输入新密码",
      resetPasswordBtn: "重设密码",
      backToLoginBtn: "回到登录",
      accountNotFound: "找不到帐户",
      emailSent: "密码重置指令已成功发送",
      changePasswordBtn: "更改密码",
      resetPasswordSuccess: "您的密码已被更改!",
      resetPasswordLinkExpired: "您的链接已过期!",
    },
    disclaimer: ({ named }) =>
      `负责任地投资：差价合约是复杂的工具，由于杠杆作用而迅速亏损的风险很高。 
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage} 的散户投资者账户在与该提供商交易差价合约时亏损。`
    }  您应该考虑是否了解差价合约的运作方式，以及您是否有能力承担损失资金的高风险。</b>`,
  },
  vnm: {
    regulation: {
      dearClient: "Kính gửi Quý khách hàng!",
      regulationText: `<strong>Benor Capital Ltd </strong> được ủy quyền và quản lý bởi Ủy ban Dịch vụ Tài chính (FSC) của Mauritius (Số Giấy phép C118023678). Xin lưu ý rằng trang web này không hướng đến các cư dân EU vì nó có thể chứa thông tin về các dịch vụ và sản phẩm không bị áp dụng các biện pháp can thiệp vào sản phẩm. Vui lòng truy cập trang web triomarkets.eu của pháp nhân được điều chỉnh tại Liên minh Châu Âu của chúng tôi. Bằng cách tiếp tục, bạn đồng ý mở tài khoản với   <strong>Benor Capital Ltd </strong> (<a class="text-purple" traget="_blank" href="https://www.triomarkets.com/about-us/legal-documents/">Điều khoản và Điều kiện</a>) và bạn xác nhận rằng đây là quyết định dựa trên quyết định duy nhất và tuyệt đối của bạn mà không có bất kỳ lời mời chào nào và / hoặc đề xuất từ phía chúng tôi.`,
      goTo: "Đi tới",
      accept: "Chấp nhận",
      privacyPolicy: `Chính sách bảo mật`,
    },
    register: {
      progress: {
        step1: {
          title: "Đăng ký",
          subTitle: "tạo tài khoản của bạn",
        },
        step2: {
          title: "Xác minh",
          subTitle: "Xác thực email của bạn",
        },
        step3: {
          title: "Giao dịch",
          subTitle: "Nạp tiền và giao dịch",
        },
      },
      steps: {
        step1: {
          title: "Đăng ký tài khoản",
          subTitle:
            "Bạn chỉ cần thực hiện một vài bước đơn giản để thực hiện giao dịch đầu tiên của mình",
          accountType: {
            individual: "Cá nhân",
            company: "Công ty",
            fname: "Tên",
            lname: "Họ",
            email: "Email",
            pass: "Mật khẩu",
            rpass: "Lặp lại mật khẩu",
            country: "Quốc gia",
            phoneExt: "Phone Ext",
            phone: "Số điện thoại",
            passwordHelp: `
            Mật khẩu phải dài ít nhất 8 ký tự và chứa ít nhất một chữ in hoa và số. Các ký tự đặc biệt được phép là:`,
          },
          validation: {
            fnameRequired: "Tên là bắt buộc",
            fnameLatin: "Tên chỉ có thể chứa các ký tự chữ cái",
            lnameRequired: "Họ là bắt buộc",
            lnameLatin: "Họ chỉ có thể chứa các ký tự chữ cái",
            companyLatin: "Công ty chỉ có thể chứa các ký tự chữ cái",
            emailRequired: "Email là cần thiết",
            emailValid: "Email phải là email hợp lệ",
            countryRequired: "Quốc gia là bắt buộc",
            phoneExtRequired: "Phone ext is required",
            phoneRequired: "Điện thoại là bắt buộc",
            companyRequired: "Công ty là bắt buộc",
            passwordRequired: "Mật khẩu là bắt buộc",
            passwordLength: "Mật khẩu phải dài ít nhất 8 ký tự",
            passwordChar: "Mật khẩu không đáp ứng yêu cầu.",
            passwordNotMatch: "Mật khẩu không phù hợp",
            eulaRequired: "Phải chấp nhận các Điều khoản và Điều kiện",
          },
          eula: ({
            named,
          }) => `Tôi xác nhận và đồng ý rằng công ty sẽ lưu giữ và xử lý Dữ liệu Cá nhân của tôi cho mục đích pháp lý theo GDPR *. Tôi đã đọc, hiểu và chấp nhận thỏa thuận
          <a class="text-purple"
            target="_blank"
            href="${named("clientAgreementUrl")}"
          >Khách hàng của</a> TrioMarkets,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >Chính sách bảo mật</a>`,
          registerBtn: "ĐĂNG KÝ",
          alreadyRegistered: "Đã đăng ký? Sau đó ",
          loginBtn: "Đăng nhập",
        },
        step2: {
          title: "Email xác thực",
          subTitle:
            "Bạn chỉ cần thực hiện 2 bước đơn giản để đặt giao dịch đầu tiên của bạn",
          oneMoreStep: ({ named }) => `Chỉ một bước nữa, ${named("name")}!`,
          verifyEmail: "Bạn cần xác minh địa chỉ email của mình",
          startAgain: "Bắt đầu lại!",
          receiveEmail: ({ named }) =>
            `Bạn sẽ nhận được một email xác minh trên <strong>${named(
              "email"
            )}</strong> chứa liên kết xác thực trong vài phút.`,
          notReceiveEmail:
            "Nếu bạn không nhận được email này, vui lòng kiểm tra thư mục thư rác và đảm bảo rằng hộp thư đến đang hoạt động và không đầy đủ.",
          resendEmail: "Nếu bạn tiếp tục gặp sự cố, vui lòng thử gửi lại",
          resendEmailLink: "email verifiaction hoặc liên hệ",
          emailResendAgain: ({ named }) =>
            `<p>Email xác minh của bạn đã được gửi lại đến <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            Bạn có thể thử gửi lại sau ${named("timer")} ${named("seconds")}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `Ôi! Có vẻ như bạn chưa xác nhận địa chỉ email của mình. <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `hoặc liên hệ ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } để được trợ giúp`,
          trioMarketsTeam: "Nhóm TrioMarkets™",
          pleaseWait: ({ named }) =>
            `Vui lòng đợi ${named("timer")} ${named("seconds")}`,
          seconds: "giây",
          continuebtn: "CONTINUE",
        },
        step3: {
          success: "Xin chúc mừng",
          activatedAccount:
            "Tài khoản của bạn hiện đang hoạt động! Bạn có thể đăng nhập và bắt đầu giao dịch!",
          verifiedAccount: "Tài khoản mới của bạn đã được xác minh thành công!",
          loginAccount:
            "Bây giờ bạn có thể đăng nhập vào tài khoản mới của mình!",
          loginBtn: "ĐI ĐẾN ĐĂNG NHẬP",
        },
      },
    },
    login: {
      email: "Email của bạn",
      password: "Mật khẩu an toàn của bạn",
      loginBtn: "Đăng nhập",
      noAccountLink: "Vẫn chưa có tài khoản?",
      clientPortal: "Đăng nhập vào Cổng thông tin khách hàng TrioMarkets™",
      signUpHere: "đăng ký tại đây",
    },
    forgotPassword: {
      title: "Quên mật khẩu?",
      password: "Nhập mật khẩu mới",
      repeatPassword: "Gõ lại mật khẩu mới",
      resetPasswordBtn: "Đặt lại mật khẩu",
      backToLoginBtn: "Quay lại đăng nhập",
      accountNotFound: "Không tìm thấy tài khoản",
      emailSent: "Đã gửi hướng dẫn đặt lại mật khẩu thành công",
      changePasswordBtn: "Đổi mật khẩu",
      resetPasswordSuccess: "Mật khẩu của bạn đã được thay đổi!",
      resetPasswordLinkExpired: "Liên kết của bạn đã hết hạn!",
    },
    disclaimer: ({ named }) =>
      `Đầu tư có trách nhiệm: CFD là công cụ phức tạp và có rủi ro cao mất tiền nhanh chóng do đòn bẩy. 
    ${
      named("license") === "global"
        ? ""
        : `<b>${percentage}tài khoản nhà đầu tư bán lẻ bị mất tiền khi giao dịch CFD với nhà cung cấp này.</b>`
    }  Bạn nên cân nhắc xem mình có hiểu cách thức hoạt động của CFD hay không và liệu bạn có thể đủ khả năng chấp nhận rủi ro cao khi mất tiền của bạn.`,
  },
  arb: {
    regulation: {
      dearClient: "عزيزي العميل !",
      regulationText: `<strong>Benor Capital Ltd</strong> مرخصة ومنظمة من قبل لجان الخدمات المالية (FSC) في موريشيوس (رقم الترخيص C118023678). يرجى ملاحظة أن موقع الويب هذا غير موجه إلى المقيمين في الاتحاد الأوروبي لأنه قد يحتوي على معلومات حول الخدمات والمنتجات التي لا تخضع لمعايير التداول الخاص بها. يرجى زيارة موقع الويب الخاص بكياننا الخاضع للتنظيم في الاتحاد الأوروبي triomarkets.eu. من خلال الموافقة <strong>Benor Capital Ltd</strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">الشروط والأحكام)</a> ، فإنك تؤكد أن هذا  القرار يعتمد على تقديرك الوحيد والمطلق دون أي التماس و / أو توصية من جانبنا.
      `,
      goTo: "إذهب إلى",
      cookies: "هذا الموقع يستخدم ملفات التعريف الخاصة بنا",
      accept: "موافقة ",
      privacyPolicy: `سياسة الخصوصية `,
    },
    register: {
      progress: {
        step1: {
          title: "تسجيل",
          subTitle: "افتح حسابك الآن",
        },
        step2: {
          title: "فعّل",
          subTitle: "تحقق من صحة بريدك الإلكتروني",
        },
        step3: {
          title: "تداول",
          subTitle: "أودع وتداول",
        },
      },
      steps: {
        step1: {
          title: "تسجيل الحساب",
          subTitle: "انت على بعد خطوات بسيطة لتحقق تداولك الأول",
          accountType: {
            individual: "فردي",
            company: "شركة",
            fname: "الإسم",
            lname: "الكنية",
            email: "البريد الإلكتروني",
            pass: "كلمة السر",
            rpass: "إعادة كلمة السر",
            country: "الدولة",
            phoneExt: "لاحقة الهاتف",
            phone: "رقم الهاتف",
            passwordHelp: `يجب أن تحتوي كلمة المرور على 8 أحرف على الأقل وحرف واحد أو أكثر من الأحرف الكبيرة. الأحرف الخاصة المسموح بها هي: ! @ $ % _ # ( ).`,
          },
          validation: {
            fnameRequired: "الإسم إلزامي",
            fnameLatin: "الإسم يجب أن يحتوي على أحرف أبجدية فقط",
            lnameRequired: "الكنية إلزامي",
            lnameLatin: "الإسم يجب أن يحتوي على أحرف أبجدية فقط",
            companyLatin: "الشركة يجب أن تحتوي على أحرف أبجدية فقط ",
            // missing from spreadsheet
            emailRequired: "البريد الإلكتروني إلزامي",

            emailValid: "يجب أن يكون بريدك الإلكتروني فعال",
            countryRequired: "الدولة إلزامي",
            phoneExtRequired: "لاحقة الهاتف إلزامية",
            phoneRequired: "الهاتف إلزامي",
            companyRequired: "الشركة إلزامي",
            passwordRequired: "كلمة السر إلزامية",
            passwordLength: "كلمة السر يجب أن تحتوي على الأقل 8 حروف",
            passwordChar: "كلمة المرور لا تفي بالمتطلبات.",
            passwordNotMatch: "كلمة السر غير متطابقة",
            eulaRequired: "يجب أن توافق على الشروط والأحكام",
          },
          eula: ({
            named,
          }) => `أقر بموجب هذا وأوافق على أن الشركة ستحتفظ ببياناتي الشخصية وتعالجها لأغراض تنظيمية وفقًا للائحة العامة لحماية البيانات (GDPR) *. لقد قرأت وفهمت وقبلت TrioMarkets <a class="text-purple" target="_blank" href="${named(
            "clientAgreementUrl"
          )}">إتفاقية العميل</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >سياسة الخصوصية</a>`,
          registerBtn: "سجل الآن",
          alreadyRegistered: "هل سجلت مسبقاً ؟ ",
          loginBtn: "تسجيل الدخول",
        },
        step2: {
          title: "التحقق من البريد الإلكتروني",
          subTitle: "انت على بعد خطوتين لإتمام عملية التداول ",
          oneMoreStep: ({ named }) => `لديك خطوة واحدة فقط  ${named("name")}!`,
          verifyEmail: "يجب أن تتحقق من بريدك الإلكتروني",
          startAgain: "ابدأ من جديد",
          receiveEmail: ({ named }) =>
            `يحتوي على رابط التحقق في غضون <strong>${named(
              "email"
            )}</strong> ستتلقى رسالة بريد إلكتروني على ... يحتوي على رابط التحقق في غضون بضع دقائق`,
          notReceiveEmail:
            "في حال لم تستقبل هذا الايميل الرجاء التحقق من البريد المهمل وأن صندوق البريد لديك محدث وغير ممتلئ",
          resendEmail: "إذا ما استمرت هذه المشكلة الرجاء حاول مجدداً",
          resendEmailLink: "تم ارسال ايميل التفعيل ",
          emailResendAgain: ({ named }) =>
            `<p>ايميل التفعيل تم إعادة أرساله لك <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            بإمكانك معاودة الإرسال مجدداً ${named("timer")} ${named("seconds")}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `لحظة!! لابد أنك لم تقم بتأكيد عنوان بريدك الإلكتروني. على كل حال قمنا بإرسال ايميل التفعيل لك مجدداً <strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `أو التواصل ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } للمساعدة`,
          trioMarketsTeam: "TrioMarkets&trade; فريق ",
          pleaseWait: ({ named }) =>
            `الرجاء الإنتظار ${named("timer")} ${named("seconds")}`,
          seconds: "ثواني",
          continuebtn: "استمر",
        },
        step3: {
          success: "تهانينا",
          activatedAccount:
            "حسابك التداول مفعل الآن! بإمكانك تسجيل الدخول والبدء بالتداول",
          verifiedAccount: "حسابك الجديد قد تفعل بنجاح",
          loginAccount: "بإمكانك الآن الدخول لحسابك الجديد!",
          loginBtn: "توجه لتسجيل الدخول",
        },
      },
    },
    login: {
      email: "بريدك الإلكتروني",
      password: "كلمة السر الخاصة بك",
      loginBtn: "تسجيل الدخول",
      noAccountLink: "ليس لديك حساب تداولي حتى الآن؟",
      clientPortal: "تسجيل الدخول إلى منطقة العملاء الخاصة ب Triomarkets™",
      signUpHere: "إنشاء حساب هنا",
    },
    forgotPassword: {
      title: "نسيت كلمة السر؟",
      password: "أدخل كلمة السر الجديدة",
      repeatPassword: "إعادة إدخال كلمة السر الجديدة",
      resetPasswordBtn: "إعادة تعيين كلمة السر",
      backToLoginBtn: "الرجوع إلى تسجيل الدخول",
      accountNotFound: "الحساب غير موجود",
      emailSent: "تعليمات تحديث كلمة السر تم إرسالها بنجاح",
      changePasswordBtn: "تغيير كلمة السر",
      resetPasswordSuccess: "تم تغيير كلمة السر الخاصة بك !",
      resetPasswordLinkExpired: "لقد انتهت صلاحية الرابط الخاص بك!",
    },
    disclaimer: () =>
      `الاستثمار بمسؤولية: العقود مقابل الفروقات هي أدوات معقدة وتنطوي على مخاطر عالية لخسارة الأموال بسرعة بسبب الرافعة المالية. يجب أن تفكر فيما إذا كنت تفهم كيفية عمل العقود مقابل الفروقات وما إذا كنت تستطيع تحمل مخاطر عالية قد تسبب خسارة أموالك`,
  },
  my: {
    regulation: {
      dearClient: "Pelanggan yang dihormati!",
      regulationText: `<strong>Benor Capital Ltd </strong> diberi kuasa dan dikawal selia oleh Suruhanjaya Perkhidmatan Kewangan ( FSC ) dari Mauritius ( Nombor Lesen<strong> C118023678</strong>). Harap maklum bahawa laman web ini tidak ditujukan kepada penduduk EU kerana mungkin mengandungi maklumat mengenai perkhidmatan dan produk yang tidak dikenakan tindakan campur tangan produk. Sila lawati laman web triomarkets.eu entiti yang dikawal selia oleh EU kami. Dengan Menginap di <strong>Benor Capital Ltd </strong> (<a target="_blank" href="https://www.triomarkets.com/legal-documents/benor/Benor_Capital_Ltd-Client_Services_Agreement.pdf">Terma dan Syarat</a>) anda mengesahkan bahawa ini adalah keputusan berdasarkan budi bicara anda sendiri dan mutlak tanpa sebarang permintaan dan/atau cadangan dari pihak kami.`,
      goTo: "Pergi ke",
      cookies: "Laman web ini menggunakan kuki.",
      accept: "Terima",
      privacyPolicy: `Dasar Privasi`,
    },
    register: {
      progress: {
        step1: {
          title: "Daftar",
          subTitle: "Buat akaun anda",
        },
        step2: {
          title: "Sahkan",
          subTitle: "Sahkan e-mel anda",
        },
        step3: {
          title: "Perdagangan",
          subTitle: "Dana dan perdagangan",
        },
      },
      steps: {
        step1: {
          title: "Pendaftaran Akaun",
          subTitle:
            "Anda hanya beberapa langkah mudah untuk meletakkan perdagangan pertama anda",
          accountType: {
            individual: "Individu",
            company: "Syarikat",
            fname: "Nama",
            lname: "Nama belakang",
            email: "E-mel",
            pass: "Kata Laluan",
            rpass: "Ulangi Kata Laluan",
            country: "Negara",
            phoneExt: "Sambungan Telefon",
            phone: "Nombor telefon",
            passwordHelp: `Kata laluan mestilah sekurang-kurangnya 8 aksara dan mengandungi sekurang-kurangnya satu huruf besar dan nombor. Watak istimewa yang dibenarkan ialah:`,
          },
          validation: {
            fnameRequired: "Nama diperlukan",
            fnameLatin: "Nama hanya boleh mengandungi watak abjad",
            lnameRequired: "Nama belakang diperlukan",
            lnameLatin: "Nama hanya boleh mengandungi watak abjad",
            companyLatin: "Syarikat hanya boleh mengandungi watak abjad",
            emailRequired: "E-mel diperlukan",
            //
            emailValid: "Email must be valid email",
            //
            countryRequired: "Negara diperlukan",
            phoneExtRequired: "Sambungan telefon diperlukan",
            phoneRequired: "Telefon diperlukan",
            companyRequired: "Syarikat diperlukan",
            passwordRequired: "Kata laluan diperlukan",
            passwordLength: "Kata laluan mestilah minimum 8 aksara",
            passwordChar: "Kata laluan tidak memenuhi keperluan.             ",
            passwordNotMatch: "Kata laluan tidak sepadan",
            eulaRequired: "Mesti Menerima Terma dan Syarat",
          },
          eula: ({
            named,
          }) => `Dengan ini saya mengakui dan bersetuju bahawa syarikat akan menyimpan dan memproses Data Peribadi saya untuk tujuan peraturan sesuai dengan GDPR*. Saya telah membaca, memahami dan menerima TrioMarkets&trade; <a class="text-purple" target="_blank" href="${named(
            "clientAgreementUrl"
          )}">Perjanjian pelanggan</a>,
          <a class="text-purple"
            target="_blank"
            href="${named("privacyPolicyUrl")}"
          >Dasar Privasi</a>`,
          registerBtn: "DAFTAR",
          alreadyRegistered: "Sudah mendaftar? Kemudian ",
          loginBtn: "Log masuk",
        },
        step2: {
          title: "Pengesahan E-mel",
          subTitle:
            "Anda hanya tinggal 2 langkah mudah dari melakukan perdagangan pertama anda",
          oneMoreStep: ({ named }) =>
            `Hanya satu langkah lagi ${named("name")}!`,
          verifyEmail: "Anda perlu mengesahkan alamat e-mel anda",
          startAgain: "Mulakan sekali lagi!",
          receiveEmail: ({ named }) =>
            `Anda akan menerima e-mel pengesahan pada <strong>${named(
              "email"
            )}</strong> mengandungi pautan pengesahan dalam beberapa minit.`,
          notReceiveEmail:
            "Sekiranya anda tidak menerima e-mel ini, periksa folder spam anda dan pastikan peti masuk aktif dan tidak penuh.",
          resendEmail: "Sekiranya anda terus mengalami masalah, cubalah",
          resendEmailLink: "menghantar semula pengesahan e-mel",
          emailResendAgain: ({ named }) =>
            `<p>E-mel pengesahan anda dihantar semula ke <br/> <strong>${named(
              "email"
            )} </strong>
            </p>
            <p>
            Anda boleh mencuba menghantar semula ${named("timer")} ${named(
              "seconds"
            )}
            </p>`,
          notConfirmedEmail: ({ named }) =>
            `OOPS! Nampaknya anda belum mengesahkan alamat e-mel anda. Sekiranya kami menghantar e-mel pengesahan baru ke<strong>${named(
              "email"
            )}</strong>`,
          contact: ({ named }) =>
            `atau kenalan ${
              named("license") === "global"
                ? "support@triomarketsglobal.com"
                : "support@triomarkets.eu"
            } untuk pertolongan`,
          trioMarketsTeam: "TrioMarkets&trade; Pasukan",
          pleaseWait: ({ named }) =>
            `Sila tunggu ${named("timer")} ${named("seconds")}`,
          seconds: "saat",
          continuebtn: "TERUS",
        },
        step3: {
          success: "Tahniah",
          activatedAccount:
            "Akaun anda kini aktif! Anda boleh log masuk dan memulakan perdagangan!",
          verifiedAccount: "Akaun baru anda berjaya disahkan!",
          loginAccount: "Anda sekarang boleh log masuk ke akaun baru anda!",
          loginBtn: "Pergi ke Log Masuk",
        },
      },
    },
    login: {
      email: "E-mel anda",
      password: "Kata laluan selamat anda",
      loginBtn: "Log masuk",
      noAccountLink: "Belum mempunyai akaun?",
      clientPortal: "Log masuk ke TrioMarkets™ Portal Pelanggan",
      signUpHere: "Daftar di sini",
    },
    forgotPassword: {
      title: "Lupa kata laluan anda?",
      password: "Masukkan kata laluan baru",
      repeatPassword: "Ketetapan kata laluan baru",
      resetPasswordBtn: "Tetapkan semula kata laluan",
      backToLoginBtn: "Kembali ke log masuk",
      accountNotFound: "Akaun tidak dijumpai",
      emailSent: "Arahan penetapan semula kata laluan berjaya dihantar",
      changePasswordBtn: "Tukar Kata Laluan",
      resetPasswordSuccess: "Kata laluan anda telah diubah!",
      resetPasswordLinkExpired: "Pautan anda telah tamat tempoh!",
    },
    disclaimer: `Melabur dengan Bertanggungjawab: CFD adalah instrumen yang kompleks dan berisiko tinggi kehilangan wang dengan cepat kerana leverage. Anda harus mempertimbangkan sama ada anda memahami bagaimana CFD berfungsi dan adakah anda mampu mengambil risiko tinggi kehilangan wang anda.`,
  },
};

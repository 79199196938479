<template>
  <div
    class="
      fund
      text-center
      mt-sm-0 mt-5
      pt-sm-0 pt-4
      d-flex
      flex-column
      justify-content-center
      align-items-center
      w-100
      py-4
    "
  >
    <h2 class="fs-1 fw-bold text-success">
      {{ $t("register.steps.step3.success") }} {{ user.name }}!
    </h2>
    <h4 class="mt-3 fs-5 fw-bold">
      {{ $t("register.steps.step3.verifiedAccount") }}
    </h4>
    <div class="pt-sm-0 mt-3 pt-5">
      <p>{{ $t("register.steps.step3.loginAccount") }}</p>
    </div>
    <div class="buttonset w-75">
      <button @click="showLogin()" class="btn btn-gradient-purple text-white w-100">
        {{ $t("register.steps.step3.loginBtn") }}!
      </button>
    </div>
  </div>
  <Confetti />
</template>
<script>
import { getters } from "../../store";
import Confetti from "../Confetti.vue";
export default {
  data() {
    return {};
  },
  components: {
    Confetti,
  },
  computed: {
    user() {
      return getters.user();
    },
    selectedLang() {
      return getters.selectedLang();
    },
  },
  methods: {
    showLogin() {
      this.$router.push(`/${this.selectedLang}/login`);
    },
    start() {
      this.$confetti.start();
    },
    stop() {
      this.$confetti.stop();
    },
  },
};
</script>
<style lang="scss" scoped></style>

import { reactive } from "vue";
export const store = reactive({
  selectedLang: "en",
  license:
    location.host.includes(".eu") || location.host.includes("8080")
      ? "eu"
      : "global",
  countries: [],
  step: 1,
  user: {
    userID: "",
    email: "",
    name: "",
    ip: "",
  },
  selectedCountry: {
    country_id: 0,
    phoneExt: "",
    currency: "",
  },
  languages: [
    {
      name: "English",
      translateName: "English",
      code: "en",
    },
    {
      name: "French",
      translateName: "Français",
      code: "fr",
    },
    {
      name: "Italian",
      translateName: "Italiano",
      code: "it",
    },
    {
      name: "German",
      translateName: "Deutsch",
      code: "de",
    },
    {
      name: "Spanish",
      translateName: "Spanish",
      code: "es",
    },
    {
      name: "Chinese",
      translateName: "Chinese",
      code: "cn",
    },
    {
      name: "Vietnamese",
      translateName: "Vietnamese",
      code: "vnm",
    },
    {
      name: "Arabic",
      translateName: "عربي",
      code: "arb",
    },
    {
      name: "Malay",
      translateName: "Melayu",
      code: "my",
    },
  ],
  cookiesEnabled: false,
  regulationAccepted: false,
  checkArb: false,
});

export const getters = {
  license() {
    return store.license;
  },
  countries() {
    return store.countries;
  },
  languages() {
    return store.languages;
  },
  clientAgreementUrl() {
    return store.license == "global"
      ? "https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Client__Services_Agreement.pdf"
      : "https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Client__Services_Agreement.pdf";
  },
  privacyPolicyUrl() {
    return store.license == "global"
      ? "https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Privacy_Policy.pdf"
      : "https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Privacy_Policy.pdf";
  },
  portfolioManagementUrl() {
    return store.license == "global"
      ? "https://www.triomarkets.com/legal-documents/EDR/Portfolio-Management.pdf"
      : "https://www.triomarkets.eu/legal-documents/EDR/Portfolio-Management.pdf";
  },
  riskDisclosureUrl() {
    return store.license == "global"
      ? "https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Risk_Disclosure_Statement.pdf"
      : "https://www.triomarkets.com/wp-content/uploads/2024/07/Benor_Capital_Ltd-Risk_Disclosure_Statement.pdf";
  },
  kidsUrl() {
    return `https://www.triomarkets.eu/${getters.selectedLang()}/company/kids`;
  },
  loginURL() {
    return `https://secure.triomarkets.${
      store.license === "eu" ? "eu" : "com"
    }/${store.selectedLang}/login`;
  },
  siteURL() {
    return store.license === "global"
      ? "https://www.triomarkets.com"
      : "https://www.triomarkets.eu";
  },
  currentStep() {
    return store.step;
  },
  user() {
    return store.user;
  },
  selectedLang() {
    return store.selectedLang;
  },
  checkArb() {
    return store.checkArb;
  },
  selectedCountry() {
    return store.selectedCountry;
  },
  cookiesEnabled() {
    return store.cookiesEnabled || localStorage.getItem("cookies");
  },
  regulationAccepted() {
    return store.regulationAccepted || localStorage.getItem("regulation");
  },
};

export const mutations = {
  changeLanguage(lang = "en") {
    let { code } =
      store.languages.find(({ code }) => {
        return code === lang;
      }) ?? "";
    store.selectedLang = code ?? "en";
    window.sessionStorage.setItem("language", store.selectedLang);
    if (store.selectedLang == "arb") {
      store.checkArb = true;
    } else {
      store.checkArb = false;
    }
  },
  nextStep(step) {
    store.step = step;
  },
  setUser(
    user = {
      userID: "",
      email: "",
      name: "",
      ip: "",
    }
  ) {
    store.user = user;
  },
  setCountries(countries) {
    store.countries = countries;
  },
  setCountry(country) {
    store.selectedCountry = country;
  },
  setCookies() {
    store.cookiesEnabled = true;
    localStorage.setItem("cookies", "accepted");
  },
  setRegulation() {
    store.regulationAccepted = true;
    localStorage.setItem("regulation", "accepted");
  },
};

<template>
  <div class="d-flex justify-content-md-center align-items-center vh-100">
    <div class="maintenance text-center">
      <article>
        <img src="/img/logo-dark.svg" alt="maintenance" class="logo mb-4" />
        <h2>We&rsquo;ll be back soon!</h2>
        <div>
          <p class="fs-5">
            Sorry for the inconvenience. We&rsquo;re performing some maintenance
            at the moment. If you need to you can always
            <a :href="`mailto:${mail}`" class="text-purple">contact us</a>
            for updates, otherwise we&rsquo;ll be back up shortly!
          </p>
          <p>&mdash; The TrioMarkets Team</p>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { getters } from "../store";
export default {
  name: "Maintenance",
  computed: {
    license() {
      return getters.license();
    },
    mail() {
      return this.license === "global"
        ? "noreply@triomarketsglobal.com"
        : "noreply@triomarkets.com";
    },
  },
};
</script>

<style>
article {
  display: block;
  width: 700px;
  padding: 50px;
  margin: 0 auto;
}
a {
  color: #fff;
  font-weight: bold;
}
.logo {
  width: 250px;
}
</style>

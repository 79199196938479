import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "../components/Login.vue";
import Register from "../components/Register.vue";
import ResetPassword from "../components/ResetPassword.vue";
const routes = [
  {
    path: "/:lang?",
    name: "home",
    component: Home,
    beforeEnter: (to, from, next) => {
      if (to.matched.length <= 1) {
        return next({
          name: "Register",
          params: { lang: window.sessionStorage.getItem("language") ?? "en" },
        });
      } else {
        return next();
      }
    },
    children: [
      {
        path: "login",
        name: "Login",
        component: Login,
      },
      {
        path: "register",
        name: "Register",
        component: Register,
      },
      {
        path: "resetPassword",
        name: "resetPassword",
        component: ResetPassword,
      },
    ],
  },
];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
